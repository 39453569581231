.property-content{
  margin-top:5px;
  position:relative;

  .favorite-icon i{
    font-size: 25px;
    margin-top:0;
  }
}
.property-cover{
  position: relative;
  min-height: 300px;
  overflow:hidden;
  margin-bottom:30px;

  .property-cover-image{
    position: absolute;
    top:-20px;left:-20px;right:-20px;bottom:-20px;
    background-size: cover;
    background-position: center center;
    -webkit-filter: blur(15px); /* Safari 6.0 - 9.0 */
    filter: blur(15px);
  }
}

.property-cover-info{
  color: #fff;
  position: relative;
  margin-top: 50px;

  h1,h2,h3,h4,a{
    color: #fff;
  }
.property-show-zone{
  padding: 6px 15px;
  border: 1px dashed #D9D9D9;
}

}


.property-delimiter-box{
  //border-radius: 5px;
  //background-color: #fff;
  //padding: 32px 35px 34px;
  margin-bottom: 30px;
  //border: 1px solid #eee;
}

.divider-header-property{
  padding: 10px 0 !important;
}

.property-wrapper{
  //padding:30px 0;
  //margin-top: 30px;


  .container{
    //background: #fff;
    //padding: 30px;
  }

  .property-content{
   // background: #fff;
   // padding: 30px;
    h2,h3,h4,h5{
      text-align:left;
    }

    h2.page-header{
    //  text-transform: uppercase;
      text-align:left;
      color: #111111;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      text-transform: capitalize;
      margin: 0 0 25px;

    }

    h2{
      color:#000;
      font-size: 18px;
      &:after{
        display: none;
      }
      .first-word{
        color:#3a3f4c;
      }
    }
  }




}


.property-aside-content{
  //background: #fff;
 // padding: 30px;
}

.property-content-gallery{
  margin: -30px -30px 0 -30px;
}


.property-main-gallery{
  .property-main-gallery-item{
    text-align:center;
    img{
      max-width: 100%;
      height: auto;
      max-height: 540px;
      display: block;
      vertical-align: middle;
      text-align: center;
      margin:0 auto;
    }
  }
}

.property-show-price{
  font-weight:300;
  //margin-top:5px;
  display: block;
  text-align: right;
  font-size:20px;
  color: @brand-color;
  float:right;
}

.property-show-short-caracteristics{
  margin-top: 30px;
  text-align: center;
  float:left;
  margin-right: 20px;

  .box-short{
    i,i:before{
      font-size:40px;
      // display:block;
    }

    .box-short-title{
      font-weight: bold;
      display: block;
    }
  }
}

.property-show-lista-tabelara{
  margin:0;
  padding:0;
  li{
    padding:0;
    list-style:none;
    border-bottom: 1px solid #f7f7f7;

    span{
      float: right;
      font-weight: bold;
    }
  }
}
.property-show-specs ul{
  list-style:none;
  .fa{
    opacity:.5;
  }
  li span{
    font-weight: bold;
  }
}

#window-map{
  height: 350px;
}

.property-show-contact-short{
  //  background:#1565C0;
  // padding: 15px;
  .agent-small-content{
    border-bottom: none;
  }
}

.agent-contact-phone{
  clear:both;
  background:@brand-color;
  padding: 15px;
  color: #fff;
  font-size: 18px;
  display: block;
  text-align: center;
  margin-top: 15px;
}

.contact-form{
  margin-top:30px;


  textarea{
    min-height: 120px;
  }
  .btn{
    width: 100%;
    color: #fff;
    background:@brand-color;
    padding: 15px 30px;
    border-radius: 5px;
  }
}


.property-show-contact-vertical{
  border: 1px solid #EEEEEE;
  padding: 30px;
  // background: #FAFAFA;
}

.btn-print{
  width: 100%;
}

.property-show-title{
  font-size: 30px;
  margin: 5px 0 30px 0;
  padding:0;
  font-weight: normal;

}

.property-show-zone{
 margin-top:0;
  font-size: 16px;
  color: #666666;
  float:left;
}

.property-show-id{
 // font-weight: bold;
  clear:both;
}
.property-show-visits{
  padding-left: 20px;
}

.property-main-gallery{
  margin-top:10px;
}

.property-main-gallery-item{
  img.img-portrait{
    width: auto !important;
  }
}

.property-content .label-wrapper{
  top:-20px;
}

.property-show-specs h5{
  font-size: 16px;
  color:#111;
}

.property-aside-content .property-delimiter-box{
  border: 1px solid #f0f0f0;
  padding: 0 15px 15px 15px;
}

.property-show-box, .property-show-description{
  margin-bottom: 30px;
}

.property-short-caracteristics{
  margin: 30px 0;
  display: table;
  width: 100%;

  .property-box-shortc{
    display: table-cell;
    vertical-align: middle;

    span{
      padding-right: 10px;
    }

    b{
      font-size: 24px;
    }
  }
}

@media (max-width: 767px) {
  .property-short-caracteristics{
    .property-box-shortc{
      span{
        display: block;
      }
    }

  }
}

.favicon-property-show{
  float:right;
  margin-right: 10px;
  .favorite-icon{
    color: #666;
  }
}